<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
========================================================================================== -->

<template>

  <div id="page-resource-list">

    <div class="px-6 pb-2 pt-6">
    <vs-button @click="activePrompt1 = true" class="w-full">Add Task</vs-button>
    <vs-prompt
        title="Add Task"
        accept-text= "Add Task"
        button-cancel = "border"
        @cancel="clearFields1"
        @accept="addTodo"
        @close="clearFields1"
        :is-valid="validateForm1"
        :active.sync="activePrompt1">
        <div>
            <form>
                <div class="vx-row">

                    <div class="vx-col ml-auto flex">

                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col w-full">
                        <vs-input v-validate="'required'" name="title" class="w-full mb-4 mt-5" placeholder="Title" v-model="taskLocal.title" :color="validateForm1 ? 'success' : 'danger'" />
                        <vs-textarea rows="5" label="Add description" v-model="taskLocal.desc" />
                    </div>
                </div>

            </form>
        </div>
    </vs-prompt>
    </div>


  <vs-button icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date())">Add</vs-button>
    <!-- ADD EVENT -->
    <vs-prompt
        class="calendar-event-dialog"
        title="Add Event"
        accept-text= "Add Event"
        @accept="addEvent"
        :is-valid="validForm"
        :active.sync="activePromptAddEvent">

        <div class="calendar__label-container flex">

            <vs-chip v-if="labelLocal != 'none'" class="text-white" :class="'bg-' + labelColor(labelLocal)">{{ labelLocal }}</vs-chip>

            <vs-dropdown vs-custom-content vs-trigger-click class="ml-auto my-2 cursor-pointer">

                <feather-icon icon="TagIcon" svgClasses="h-5 w-5" class="cursor-pointer" @click.prevent></feather-icon>

                <vs-dropdown-menu style="z-index: 200001">
                        <vs-dropdown-item v-for="(label, index) in calendarLabels" :key="index" @click="labelLocal = label.value">
                            <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
                            <span>{{ label.text }}</span>
                        </vs-dropdown-item>

                        <vs-dropdown-item @click="labelLocal = 'none'">
                            <div class="h-3 w-3 mr-1 inline-block rounded-full mr-2 bg-primary"></div>
                            <span>None</span>
                        </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

        </div>

        <vs-input name="event-name" v-validate="'required'" class="w-full" label-placeholder="Event Title" v-model="title"></vs-input>
        <div class="my-4">
            <small class="date-label">Start Date</small>
            <datepicker :language="$vs.rtl ? langHe : langEn" name="start-date" v-model="startDate" :disabled="disabledFrom"></datepicker>
        </div>
        <div class="my-4">
            <small class="date-label">End Date</small>
            <datepicker :language="$vs.rtl ? langHe : langEn" :disabledDates="disabledDatesTo" name="end-date" v-model="endDate"></datepicker>
        </div>
        <vs-input name="event-url" v-validate="'url'" class="w-full mt-6" label-placeholder="Event URL" v-model="url" :color="!errors.has('event-url') ? 'success' : 'danger'"></vs-input>

    </vs-prompt>

        <div class="m-6 clearfix">
            <vs-button class="bg-primary-gradient w-full" icon-pack="feather" icon="icon-edit" @click="activePrompt2 = true">Compose</vs-button>
        </div>

        <!-- compose email -->
        <vs-prompt
            class="email-compose"
            title="New Message"
            accept-text= "Send"
            @cancel="clearFields2"
            @accept="sendMail"
            @close="clearFields2"
            :is-valid="validateForm2"
            :active.sync="activePrompt2">
                <component :is="scrollbarTag" class="scroll-area p-4" :settings="settings" :key="$vs.rtl">
                    <form @submit.prevent>
                        <vs-input v-validate="'required|email'" name="mailTo" label-placeholder="To" v-model="mailTo" class="w-full mb-6" :danger="!validateForm2 && mailTo != ''" val-icon-danger="clear" :success="validateForm2" val-icon-success="done" :color="validateForm2 ? 'success' : 'danger'" />
                        <vs-input name="mailSubject" label-placeholder="Subject" v-model="mailSubject" class="w-full mb-6" />
                        <vs-input name="mailCC" label-placeholder="CC" v-model="mailCC" class="w-full mb-6" />
                        <vs-input name="mailBCC" label-placeholder="BCC" v-model="mailBCC" class="w-full mb-6" />
                        <quill-editor v-model="mailMessage" :options="editorOption" />
                        <vs-upload multiple text="Attachments" :show-upload-button="false" class="compose-mail-attachment" />
                    </form>
                </component>
        </vs-prompt>
    <!-- vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters" @remove="resetColFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Role</label>
          <v-select :options="roleOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="roleFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Status</label>
          <v-select :options="statusOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="statusFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Verified</label>
          <v-select :options="isVerifiedOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="isVerifiedFilter" class="mb-4 sm:mb-0" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Department</label>
          <v-select :options="departmentOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="departmentFilter" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Type</label>
          <v-select :options="typeOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="typeFilter" />
        </div>

      </div>
    </vx-card -->

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ resourcesData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : resourcesData.length }} of {{ resourcesData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button>
          <vs-button @click="$refs.uploadImgInput.click()">Add New Resource</vs-button>

            <div class="centerx">
    <vs-button @click="popupActivo4=true" color="danger" type="border">Open fullscreen popup</vs-button>
    <!-- vs-popup fullscreen title="fullscreen" :active.sync="popupActivo4" -->
              <vs-popup fullscreen
            class="email-compose"
            title="New Message"
            accept-text= "Send"
            @cancel="clearFields2"
            @accept="sendMail"
            @close="clearFields2"
            :is-valid="validateForm2"
            :active.sync="popupActivo4">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>

        <!-- vs-prompt
            class="email-compose"
            title="New Message"
            accept-text= "Send"
            @cancel="clearFields2"
            @accept="sendMail"
            @close="clearFields2"
            :is-valid="validateForm2"
            :active.sync="popupActivo4" -->
                <component :is="scrollbarTag" class="scroll-area p-4" :settings="settings" :key="$vs.rtl">
                    <form @submit.prevent>
                        <vs-input v-validate="'required|email'" name="mailTo" label-placeholder="To" v-model="mailTo" class="w-full mb-6" :danger="!validateForm2 && mailTo != ''" val-icon-danger="clear" :success="validateForm2" val-icon-success="done" :color="validateForm2 ? 'success' : 'danger'" />
                        <vs-input name="mailSubject" label-placeholder="Subject" v-model="mailSubject" class="w-full mb-6" />
                        <vs-input name="mailCC" label-placeholder="CC" v-model="mailCC" class="w-full mb-6" />
                        <vs-input name="mailBCC" label-placeholder="BCC" v-model="mailBCC" class="w-full mb-6" />
                        <quill-editor v-model="mailMessage" :options="editorOption" />
                        <vs-upload multiple text="Attachments" :show-upload-button="false" class="compose-mail-attachment" />
                    </form>
                </component>
        <!-- /vs-prompt -->

    </vs-popup>
  </div>

      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="resourcesData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>


  </div>




</template>

<script>
//import axios from '@/axios.js'
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

// Store Module
import moduleResource from '@/store/resource/moduleResource.js'


// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue"
import CellRendererAvailability from "./cell-renderer/CellRendererAvailability.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue"
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import Datepicker from 'vuejs-datepicker'
import { en, he } from 'vuejs-datepicker/src/locale'

export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererAvailability,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
        quillEditor,
    VuePerfectScrollbar,
     Datepicker

  },
  data() {
    return {
    //resourcesData:[],
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: '',
      startDate: '',
      endDate: '',
      labelLocal: 'none',

      langHe: he,
      langEn: en,

      url: '',
      calendarView: 'month',

      activePromptAddEvent: false,
      activePromptEditEvent: false,

      calendarViewTypes: [
        {
          label: 'Month',
          val: 'month'
        },
        {
          label: 'Week',
          val: 'week'
        },
        {
          label: 'Year',
          val: 'year'
        }
      ],
          activePrompt1: false,
          activePrompt2: false,
                mailTo: '',
      mailSubject: '',
      mailCC: '',
      mailBCC: '',
      mailMessage: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'font': [] }]
          ]
        },
        placeholder: 'Message'
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
  tasks: [],
  todoSearchQuery: '',
  todoFilter: null,
      taskLocal: {
        title: '',
        desc: '',
        isCompleted: false,
        isImportant: false,
        isStarred: false,
        tags: []
      },
      popupActivo4:false,
      // Filter Options

      typeFilter: { label: 'All', value: 'all' },
      typeOptions: [
        { label: 'All', value: 'all' },
        { label: 'type1', value: 'type1' },
        { label: 'type2', value: 'type2' },
        { label: 'type3', value: 'type3' },
      ],

      roleFilter: { label: 'All', value: 'all' },
      roleOptions: [
        { label: 'All', value: 'all' },
        { label: 'Admin', value: 'admin' },
        { label: 'User', value: 'user' },
        { label: 'Staff', value: 'staff' },
      ],

      statusFilter: { label: 'All', value: 'all' },
      statusOptions: [
        { label: 'All', value: 'all' },
        { label: 'Active', value: 'active' },
        { label: 'Deactivated', value: 'deactivated' },
        { label: 'Blocked', value: 'blocked' },
      ],

      isVerifiedFilter: { label: 'All', value: 'all' },
      isVerifiedOptions: [
        { label: 'All', value: 'all' },
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],

      departmentFilter: { label: 'All', value: 'all' },
      departmentOptions: [
        { label: 'All', value: 'all' },
        { label: 'Sales', value: 'sales' },
        { label: 'Development', value: 'development' },
        { label: 'Management', value: 'management' },
      ],

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 125,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          width: 410,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Cost rate',
          field: 'costRate',
          filter: true,
          width: 150,
        }
        ,{
          headerName: 'Type',
          field: 'type',
          filter: true,
          width: 150,
        },
              {
          headerName: 'Department',
          field: 'department',
          filter: false,
          width: 150
        },
                      {
          headerName: 'Group',
          field: 'groupType',
          filter: false,
          width: 150
        },
      {
          headerName: 'Availability',
          field: 'type',
          filter: false,
          width: 150,
          cellRendererFramework: 'CellRendererAvailability'
        } /* ,
              {
          headerName: 'Status
          field: 'status',
          filter: false,
          width: 150,
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          headerName: 'Verified',
          field: 'is_verified',
          filter: true,
          width: 125,
          cellRendererFramework: 'CellRendererVerified',
          cellClass: "text-center"
        },
        {
          headerName: 'Department',
          field: 'department',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 150,
          cellRendererFramework: 'CellRendererActions',
        },*/
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      }
    }
  },
  watch: {

    typeFilter(obj) {
      this.setColumnFilter("type", obj.value)
    },
    roleFilter(obj) {
      this.setColumnFilter("role", obj.value)
    },
    statusFilter(obj) {
      this.setColumnFilter("status", obj.value)
    },
    isVerifiedFilter(obj) {
      let val = obj.value === "all" ? "all" : obj.value == "yes" ? "true" : "false"
      this.setColumnFilter("is_verified", val)
    },
    departmentFilter(obj) {
      this.setColumnFilter("department", obj.value)
    },
  },
  computed: {

        validForm () {
      return this.title !== '' && this.startDate !== '' && this.endDate !== '' && Date.parse(this.endDate) - Date.parse(this.startDate) >= 0 && !this.errors.has('event-url')
    },
    disabledDatesTo () {
      return { to: new Date(this.startDate) }
    },
    disabledDatesFrom () {
      return { from: new Date(this.endDate) }
    },
    calendarLabels () {
      return "this.$store.state.calendar.eventLabels"
    },
    labelColor () {
      return (label) => {
        if      (label === 'business') return 'success'
        else if (label === 'work')     return 'warning'
        else if (label === 'personal') return 'danger'
        else if (label === 'none')     return 'primary'
      }
    },


        validateForm1 () {
      return !this.errors.any() && this.taskLocal.title !== ''
    },
    baseUrl () {
      const path = this.$route.path
      return path.slice(0, path.lastIndexOf('/'))
    },
    emailMeta () {
      return this.$store.state.email.meta
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },

    validateForm () {
      return !this.errors.any() && this.mailTo !== ''
    },

    validateForm2 () {
 return !this.errors.any() && this.mailTo !== ''
    },
   resourcesData() {
      return this.$store.state.moduleResource.resources
    },
    paginationPageSize() {
      if(this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages() {
      if(this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    addEvent () {
      const obj = { title: this.title, startDate: this.startDate, endDate: this.endDate, label: this.labelLocal, url: this.url }
      obj.classes = `event-${  this.labelColor(this.labelLocal)}`
      this.$store.dispatch('calendar/addEvent', obj)
    },
    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
    },
    clearFields () {
      this.title = this.endDate = this.url = ''
      this.id = 0
      this.labelLocal = 'none'
    },
    promptAddNewEvent (date) {
      this.disabledFrom = false
      this.addNewEventDialog(date)
    },
    addNewEventDialog (date) {
      this.clearFields()
      this.startDate = date
      this.endDate = date
      this.activePromptAddEvent = true
    },

        clearFields1 () {
      Object.assign(this.taskLocal, {
        title: '',
        desc: '',
        isCompleted: false,
        isImportant: false,
        isStarred: false,
        tags: []
      })
    },
        clearFields2 () {
      this.$nextTick(() => {
        this.mailTo = ''
        this.mailSubject = ''
        this.mailCC = ''
        this.mailBCC = ''
        this.mailMessage = ''
      })
    },
    sendMail () {
      this.clearFields()
    },
    addTodo () {

      /*
      taskLocal
      */
     //this.$validator.validateAll().then(result => {
       // if (result) {
    if (!moduleResource.isRegistered) {
      this.$store.registerModule('moduleResource', moduleResource)
      moduleResource.isRegistered = true
    }
          console.log(this.taskLocal)

/*


{
  "weeklyScheduleAvailabilityIntervalsForDays": [
    {
      "availabilityIntervals": [
        {
          "availability": "yes",
          "availabilityIntervalId": "string",
          "endFullTime": [
            0,0,0,12,59,59,1,0
          ],
          "note": "morning",
          "startFullTime": [
            0,0,0,9,30,0,1,0
          ],
          "type": "string"
        },
        {
          "availability": "no",
          "availabilityIntervalId": "string",
          "endFullTime": [
            0,0,0,13,59,59,1,0
          ],
          "note": "break",
          "startFullTime": [
            0,0,0,13,0,0,1,0
          ],
          "type": "string"
        },
        {
          "availability": "yes",
          "availabilityIntervalId": "string",
          "endFullTime": [
            0,0,0,17,59,59,1,0
          ],
          "note": "evening",
          "startFullTime": [
            0,0,0,14,0,0,1,0
          ],
          "type": "string"
        }
      ],
      "dayOfWeek": "Monday"
    },
    {
      "availabilityIntervals": [
        {
          "availability": "yes",
          "availabilityIntervalId": "string",
          "endFullTime": [
            0,0,0,12,59,59,1,0
          ],
          "note": "morning",
          "startFullTime": [
            0,0,0,9,30,0,1,0
          ],
          "type": "string"
        },
        {
          "availability": "no",
          "availabilityIntervalId": "string",
          "endFullTime": [
            0,0,0,13,59,59,1,0
          ],
          "note": "break",
          "startFullTime": [
            0,0,0,13,0,0,1,0
          ],
          "type": "string"
        },
        {
          "availability": "yes",
          "availabilityIntervalId": "string",
          "endFullTime": [
            0,0,0,17,59,59,1,0
          ],
          "note": "evening",
          "startFullTime": [
            0,0,0,14,0,0,1,0
          ],
          "type": "string"
        }
      ],
      "dayOfWeek": "Tuesday"
    }
  ]
}

      var vendFullTime = [];
     var vstartFullTime = [];
     vendFullTime.push(fullDateToArray[2]);
     //vendFullTime.push(17);
     vendFullTime.push(fullDateToArray[1] + 1);
     vendFullTime.push(fullDateToArray[0]);
     vendFullTime.push(23);
     vendFullTime.push(0);
     vendFullTime.push(0);
     vendFullTime.push(1);
     vendFullTime.push(0);

     vstartFullTime.push(fullDateToArray[2]);
     //vstartFullTime.push(17);
     vstartFullTime.push(fullDateToArray[1] + 1);
     vstartFullTime.push(fullDateToArray[0]);
     vstartFullTime.push(7);
     vstartFullTime.push(0);
     vstartFullTime.push(0);
     vstartFullTime.push(1);
     vstartFullTime.push(0);
   const payload = {
    startFullTime:vstartFullTime,
      endFullTime:vendFullTime,
      //startFullTime: fullDateToArray[2] + "," + (fullDateToArray[1] + 1)+ "," + fullDateToArray[0] + ",0,0,0,1,30",
      //endFullTime: fullDateToArray[2] + "," + (fullDateToArray[1] + 1) + "," +fullDateToArray[0] + ",23,59,59,1,30",
      intervalInSeconds: 900,
      organizationId: 1,
      resourceId : this.selectedPackgId,
      resourceType: "packg" ,
      dayNo:this.currentDate
    }
*/

  var resource =
  {
  availabilityIntervalIndexs: [],
  availabilityIntervals: [],
  costOfPurchase: 10,
  costRate: 10,
  depreciationCost: 90,
  groupType: "string9",
  maintainmentCost: 10,
  name: "string",
  organizationId: 9,
  timeOfPurchase: ["","","","","","","",""],
  totalEquipmentCost: 10,
  totalTime: 10,
  type: "string3"
};



          this.$store.dispatch('moduleResource/addTask', Object.assign({}, resource))
          //console.log("-- START this.$store.dispatch('moduleResource/addTask', Object.assign({}, resource))");
          //console.log(this.$store.dispatch('moduleResource/addTask', Object.assign({}, resource)));
          //console.log("-- END this.$store.dispatch('moduleResource/addTask', Object.assign({}, resource))");







          this.clearFields()
       // }
     // })
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if(val !== "all") {
        modelObj = { type: "equals", filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'All', value: 'all' }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if(this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
      header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
    }
  },
  created() {
    //this.fetchResources();
        if (!moduleResource.isRegistered) {
      this.$store.registerModule('moduleResource', moduleResource)
      moduleResource.isRegistered = true
    }
    this.$store.dispatch("moduleResource/fetchResources").catch(err => { console.error(err) })
  }
}

</script>

<style lang="scss">
#page-resource-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
