<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.value)" @click="$router.push(url)">

      <span>  <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ params.value }}</router-link></span>
    </vs-chip>

</template>

  
<script>
    export default {
        name: 'CellRendererStatus',
        computed: {
          chipColor() {
            return (value) => {
              if(value === "active") return "success"
              else if(value === "blocked") return "danger"
              else if(value === "deactivated") return "warning"
              else "primary"
              }
            },
            url() {
            return "/apps/resource-personnel/resourceAvailability/"  + this.params.data.resourceId

            // Below line will be for actual product
            // Currently it's commented due to demo purpose - Above url is for demo purpose
            // return "/apps/user/user-view/" + this.params.data.id
          
          
          }
        }
    }
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
